import * as React from "react";
import { useGetList, SelectArrayInput, AutocompleteArrayInput } from "react-admin";
import PropTypes from "prop-types";

const SelectCategories = (props) => {
  const { label } = props;
  const { data, ids, loading, error } = useGetList(
    "categories",
    {},
    { field: "categoryName", order: "ASC" }
  );

  if (loading) return [];
  if (error) return [];
  if (!data) return [];

  return (
    <AutocompleteArrayInput
      label={label}
      source="categoryIds"
      choices={ids.map((id) => {
        return { id: id, name: `${data[id].categoryName}` };
      })}
      fullWidth
    />
  );
};

SelectCategories.propTypes = {
  label: PropTypes.string,
};

export default SelectCategories;
