import React from 'react';
import ApexCharts from 'react-apexcharts';
import { Box, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from 'react-loading-skeleton';

import NoDataToShow from '../../components/NoDataToShow';
import thresholdColor from '../../utils/threshold';

const themeOptions = ({ compact, fluidState }) => ({
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: '#333',
        startAngle: -90,
        endAngle: 90,
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.15,
        },
      },
      dataLabels: {
        name: {
          color: '#6e6e6e',
        },
        value: {
          color: '#6e6e6e',
          fontSize: compact ? '26px' : '40px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          offsetY: compact ? -40 : -50,
          show: true,
          formatter: val => (val === 'N/A' ? val : `${val}%`),
        },
      },
    },
  },
  maintainAspectRatio: false,
  fill: {
    colors: [({ value }) => thresholdColor(fluidState, value)],
  },
  labels: ['full'],
  legend: { show: false },
  responsive: [
    {
      breakpoint: 454,
      options: { padding: 0 },
    },
  ],
  grid: {
    show: false,
    padding: {
      left: 20,
      right: 20,
      top: 0,
      bottom: 0,
    },
  },
});

const GuagePanel = (props) => {
  const {
    classes, theme, value, compact, desc,
  } = props;

  if (value === undefined) {
    return <Skeleton height="100%" />;
  }

  if (!value) {
    return (
      <Paper className={classes.root}>
        <NoDataToShow />
      </Paper>
    );
  }

  return (
    <Paper>
      <Box
        m={0}
        maxWidth={compact ? undefined : theme.spacing(70)}
        height={compact ? 246 : theme.spacing(40)}
        pt={compact ? 3 : 0}
      >
        {desc && (
        <Grid container justify="center">
          <Grid item>
            <Typography className={classes.desc} color="textSecondary" align="center" variant={compact && 'caption'}>
              {desc}
            </Typography>
          </Grid>
        </Grid>
        )}
        <ApexCharts
          options={themeOptions(props)}
          series={(value && [value]) || ['N/A']}
          type="radialBar"
          height={compact ? theme.spacing(40) : theme.spacing(70)}
        />
      </Box>
    </Paper>
  );
};

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  chartContainer: {
    padding: 0,
    margin: 0,
    maxWidth: theme.spacing(70),
    height: theme.spacing(40),
  },
  chartContainerCompact: {
    paddingTop: theme.spacing(3),
    margin: 0,
    height: 246,
  },
  desc: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(GuagePanel);
