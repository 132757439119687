import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  minLength,
  DateTimeInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextField,
  CardActions,
  BooleanInput,
  ArrayInput,
  ArrayField,
  Datagrid,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteArrayInput,
} from "react-admin";

import timestampFormat from "../components/DateFormat";
import { useRecordContext } from "react-admin";
const NoneActions = (props) => <CardActions />;

const redirect = () => "/videos";

const validateName = [required(), minLength(2)];
const validateList = [required()];

export default ({ permissions, ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput disabled source="id" label="ID" fullWidth />
        <TextInput
          source="thumbnailUrl"
          label="Thumbnail"
          validate={validateName}
          fullWidth
        />
        <TextInput
          source="videoTitle"
          label="Title"
          fullWidth
          validate={validateName}
        />
        <TextInput source="videoSubtitle" label="Subtitle" fullWidth />
        {/* <ArrayInput source="categories">
          <SimpleFormIterator inline>
            <ReferenceInput source="id" reference="categories">
              <TextInput source="categoryName"></TextInput>
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput> */}
        {/* <SelectArrayInput source="categories"></SelectArrayInput> */}
        <ReferenceArrayInput
          label="Categories"
          source="categoryIds"
          reference="categories"
          validate={validateList}
          perPage={100}
          sort={{ field: "createdDate", order: "DESC" }}
          fullWidth
        >
          <AutocompleteArrayInput optionText="categoryName" fullWidth />
        </ReferenceArrayInput>

        <DateTimeInput
          label="Available From"
          source="availableFrom"
          required
          validate={validateName}
        />
        <DateTimeInput label="Available To" source="availableTo" />

        <TextInput
          source="videoLength"
          label="Duration"
          validate={validateName}
          fullWidth
        />
        <BooleanInput source="showWatermark" fullWidth />
        <TextField
          source="views"
          label="Views"
          validate={validateName}
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};
