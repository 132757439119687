import { useMediaQuery } from "@material-ui/core";
import React from "react";
import RerenderVideoJob from "./RerenderVideoJob";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
  NumberField,
} from "react-admin";
import timestampFormat from "../components/DateFormat";

export default ({ permissions, ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List {...props} sort={{ field: "createdDate", order: "DESC" }}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.videoId}
          secondaryText={(record) => record.status}
          tertiaryText={(record) => record.source_service}
        />
      ) : (
        <Datagrid>
          <TextField source="id" label="jobID" />
          <TextField source="videoId" label="videoID" />
          <ReferenceField source="videoId" label="videoName" reference="videos">
            <TextField source="videoTitle"></TextField>
          </ReferenceField>
          <TextField source="status" label="Status" />
          <TextField source="progress.step" label="Progress Step" />
          <TextField source="progress.progress" label="Progress Percentage" />
          <TextField source="source_service" label="Source Service" />
          <TextField source="source" label="Source" />
          <NumberField source="thumbnail_moment" label="Thumbnail Moment" />
          <FunctionField
            label="Resolutions"
            render={(record) => `${record.resolutions}`}
          />
          <FunctionField
            label="Created"
            source="createdDate"
            render={(record) => timestampFormat(record.createdDate)}
          />
          <RerenderVideoJob label="Re Render" />
        </Datagrid>
      )}
    </List>
  );
};
