import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import DeviceList from "./DeviceList";
import DeviceEdit from "./DeviceEdit";
import DeviceCreate from "./DeviceCreate";

export default {
  list: DeviceList,
  edit: DeviceEdit,
  create: DeviceCreate,
  icon: PhoneIphoneIcon,
};
