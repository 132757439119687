import ConfigIcon from "@material-ui/icons/Tune";
import ConfigList from "./ConfigList";
import ConfigEdit from "./ConfigEdit";
import ConfigCreate from "./ConfigCreate";

export default {
  list: ConfigList,
  edit: ConfigEdit,
  create: ConfigCreate,
  icon: ConfigIcon,
};
