import React from 'react';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import LocationIcon from '@material-ui/icons/PinDrop';
import Check from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  icon: { marginRight: theme.spacing(1) },
  selected: { backgroundColor: theme.palette.primary.main },
  list: { maxHeight: theme.spacing(30), overflow: 'visible' },
}));

const LocationFilter = (props) => {
  const { fullScreen, onSelected } = props;

  const classes = useStyles();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const locations = useSelector(state => state.locationData.locations);
  const selectedLocation = useSelector(state => state.locationData.selectedLocation);
  const [open, setOpen] = React.useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleLocationSelect = (selected) => {
    setOpen(false);
    onSelected(selected);
  };

  return (
    <>
      <Button
        fullWidth={isSmall}
        variant="contained"
        color="primary"
        aria-labelledby="dialog"
        onClick={selectedLocation ? openDialog : null}
      >
        <LocationIcon className={classes.icon} />
        {selectedLocation
          ? selectedLocation.desc
          : 'Fetching locations...'}
        <ArrowDropDown />
      </Button>

      {selectedLocation ? (
        <Dialog open={open} onClose={closeDialog} maxWidth="xs" fullWidth fullScreen={fullScreen}>
          <DialogTitle id="dialog">Select a location</DialogTitle>
          <Divider />
          <List className={classes.list}>
            {locations.map(l => (
              <ListItem button key={l.id} onClick={() => handleLocationSelect(l)}>
                <ListItemAvatar>
                  {selectedLocation.id === l.id ? (
                    <Avatar className={classes.selected}>
                      <Check />
                    </Avatar>
                  ) : (
                    <Avatar>
                      <LocationIcon />
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText primary={`${l.name}, ${l.address.name}`} />
              </ListItem>
            ))}
          </List>
        </Dialog>
      ) : null}
    </>
  );
};


export default compose(withMobileDialog())(LocationFilter);
