import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceArrayInput,
  TextInput,
  PasswordInput,
  BooleanInput,
  SelectArrayInput,
  required,
  minLength,
  email,
  number,
} from "react-admin";
import UserNameInput from "./UserNameInput";

const redirect = () => "/users";

const validateEmail = [required(), email()];
const validateRequired = required();
const validateName = [required(), minLength(2)];
const validatePassword = [required(), minLength(6)];
const validateNumber = number();

// const userNameValidators = [required(), ValidateUserNameUnicity];

export default (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput source="id" label="ID" disabled />
        <TextInput source="profileName" label="Name" validate={validateName} />
        <TextInput
          source="santId"
          labelName="SantId"
          validate={validateNumber}
        />
        <TextInput
          source="userName"
          labelName="Username"
          validate={validateName}
        />
        {/* <PasswordInput source="password" label="Password" required={true} fullWidth /> */}
        <TextInput source="photoURL" label="Image" />
        <BooleanInput source="enabled" />
      </SimpleForm>
    </Create>
  );
};

//style = {{ width: "500px" }}
