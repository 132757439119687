import React, { createElement } from "react";
import { useSelector } from "react-redux";
import { MenuItemLink, getResources, WithPermissions } from "react-admin";
import TankIcon from "@material-ui/icons/Gradient";
import DistributorIcon from "@material-ui/icons/LocalShipping";
import LocationIcon from "@material-ui/icons/PinDrop";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AdminIcon from "@material-ui/icons/Dvr";
import { Box, useMediaQuery } from "@material-ui/core";
import SubMenu from "./subMenu";

const Menu = (props) => {
  const { onMenuClick, logout } = props;

  const [dashboard, setDashboard] = React.useState(true);
  const [admin, setAdmin] = React.useState(true);
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector((state) => getResources(state));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const toggleDashboard = () => {
    setDashboard((prev) => !prev);
  };

  const toggleAdmin = () => {
    setAdmin((prev) => !prev);
  };

  return (
    <Box mt={3}>
      <WithPermissions
        render={({ permissions }) => {
          if (!permissions) {
            return null;
          }
          const { role } = permissions;
          console.log(permissions);
          return (
            <div>
              {(role === "Root" || role === "Admin") &&
                resources
                  .filter(
                    (resource) => resource.hasList && resource.options.admin
                  )
                  .map((resource) => (
                    <MenuItemLink
                      key={resource.name}
                      to={`/${resource.name}`}
                      primaryText={resource.options.label}
                      leftIcon={createElement(resource.icon)}
                      sidebarIsOpen={open}
                      onClick={onMenuClick}
                    />
                  ))}
              {isSmall && logout}
            </div>
          );
        }}
      />
    </Box>
  );
};

export default Menu;
