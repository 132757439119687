import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { Login, LoginForm } from "react-admin";
import ForgotPassword from "./ForgotPassword";
import Background from "../background.jpg";
import Logo from "../logo.png";

const MyLoginForm = () => {
  const [click, setClick] = useState(false);

  const forgotPassword = () => {
    setClick(true);
  };

  const updateState = () => {
    setClick(false);
  };

  return (
    <React.Fragment>
      <Grid container justify="center" alignItems="center">
        <img alt="logo" src={Logo} width="100" height="100" />
      </Grid>
      <LoginForm />
      <Grid container justify="center" alignItems="center">
        <Button
          color="primary"
          onClick={forgotPassword}
          style={{ marginBottom: "8px" }}
        >
          Forgot Password
        </Button>
      </Grid>
      {click && <ForgotPassword updateState={updateState} />}
    </React.Fragment>
  );
};

export default () => (
  <Login backgroundImage={Background}>
    <MyLoginForm />
  </Login>
);
