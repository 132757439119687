import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  minLength,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  FileInput,
  FileField,
  FunctionField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  AutocompleteArrayInput,
} from "react-admin";
import { useHistory } from "react-router-dom";
import { useRecordContext, useCreate } from "react-admin";
import { CustomFileInput } from "./CustomComponent";
import { Chip } from "@material-ui/core";

const redirect = () => "/docs";

const validateName = [required(), minLength(2)];
const validateList = [required()];

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect={redirect}>
      <SelectInput
        source="type"
        label="Type"
        defaultValue="pdf"
        choices={[
          { id: "dir", name: "Folder" },
          { id: "pdf", name: "PDF File" },
        ]}
        validate={validateList}
        fullWidth
      />
      <TextInput source="name" label="Name" validate={validateName} fullWidth />
      <TextInput source="desc" label="Description" fullWidth />
      <TextInput
        disabled
        source="parentId"
        defaultValue={
          localStorage.getItem("parentId")
            ? localStorage.getItem("parentId")
            : "root"
        }
        label="Parent Id"
        validate={validateName}
        fullWidth
      />
      <CustomFileInput label="Pdf Document" />

      <ReferenceArrayInput
        label="Allowed Traversal Groups"
        source="traversalPGIds"
        reference="permission-groups"
        perPage={100}
        fullWidth
        disabled
      >
        <SelectArrayInput optionText="permissionGroupName" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Allowed Inherited Groups"
        source="inheritedPGIds"
        reference="permission-groups"
        perPage={100}
        fullWidth
        disabled
      >
        <SelectArrayInput optionText="permissionGroupName" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Permitted Groups"
        source="permittedGroups"
        reference="permission-groups"
        perPage={100}
        fullWidth
        sort={{ field: "permissionGroupName", order: "ASC" }}
      >
        <AutocompleteArrayInput optionText="permissionGroupName" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Permitted Users"
        source="permittedUsers"
        reference="users"
        sort={{ field: "profileName", order: "ASC" }}
        perPage={100}
        fullWidth
      >
        <AutocompleteArrayInput
          optionText={(record) => record.userName + " - " + record.profileName}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Permitted Devices"
        source="permittedDevices"
        reference="devices"
        sort={{ field: "deviceName", order: "ASC" }}
        perPage={100}
        fullWidth
      >
        <AutocompleteArrayInput optionText="deviceName" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
