import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  minLength,
  SelectInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  AutocompleteArrayInput,
} from "react-admin";
import { StatusInput } from "./CustomComponent";

const redirect = () => "/docs";

const validateName = [required(), minLength(2)];
const validateList = [required()];

export default ({ permissions, ...props }) => (
  <Edit {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput disabled source="id" label="ID" fullWidth />
      <TextInput
        source="type"
        label="Type"
        disabled
        validate={validateName}
        fullWidth
      />
      <TextInput source="name" label="Name" validate={validateName} fullWidth />
      <TextInput source="desc" label="Description" fullWidth />

      <StatusInput
        label="Status"
        source="status"
        checked={(formValue) => {
          console.log(formValue);
          return formValue === 1 ? true : false;
        }}
        format={(formValue) => {
          console.log(formValue);
          return formValue === 1 ? true : false;
        }}
        parse={(inputValue) => (inputValue === true ? 1 : -1)}
      />
      <ReferenceArrayInput
        label="Allowed Traversal Groups"
        source="traversalPGIds"
        reference="permission-groups"
        perPage={100}
        fullWidth
        disabled
      >
        <SelectArrayInput optionText="permissionGroupName" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Allowed Inherited Groups"
        source="inheritedPGIds"
        reference="permission-groups"
        perPage={100}
        fullWidth
        disabled
      >
        <SelectArrayInput optionText="permissionGroupName" />
      </ReferenceArrayInput>
      {/* <ReferenceArrayInput
        label="Permission Group"
        source="allowedPGIds"
        reference="permission-groups"
        perPage={100}
        sort={{ field: "permissionGroupName", order: "ASC" }}
        fullWidth
      >
        <AutocompleteArrayInput optionText="permissionGroupName" />
      </ReferenceArrayInput> */}
      <ReferenceArrayInput
        label="Permitted Groups"
        source="permittedGroups"
        reference="permission-groups"
        perPage={100}
        fullWidth
        sort={{ field: "permissionGroupName", order: "ASC" }}
      >
        <AutocompleteArrayInput optionText="permissionGroupName" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Permitted Users"
        source="permittedUsers"
        reference="users"
        sort={{ field: "profileName", order: "ASC" }}
        perPage={100}
        fullWidth
      >
        <AutocompleteArrayInput
          optionText={(record) => record.userName + " - " + record.profileName}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Permitted Devices"
        source="permittedDevices"
        reference="devices"
        sort={{ field: "deviceName", order: "ASC" }}
        perPage={100}
        fullWidth
      >
        <AutocompleteArrayInput optionText="deviceName" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);
