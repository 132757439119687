import React from "react";
import { Admin, Resource } from "react-admin";

import { createMuiTheme } from "@material-ui/core";
import customRoutes from "./customRoutes";
import history from "./utils/history";

// providers
import dataProvider from "./dataProvider";
import authProvider from "./authProvider";

// layouts
import appLayout from "./layouts";
import LogoutButton from "./layouts/LogoutButton";

// dashboard
import TankDashboard from "./dashboard/TankDashboard";
import LocationDashboard from "./dashboard/LocationDashboard";
import DistributorDashboard from "./dashboard/DistributorDashboard";

// login
import { LoginPage } from "./login";

// admin
import users from "./admin/users";
import devices from "./admin/devices";
import adminUsers from "./admin/admin-users";
import permissionGroups from "./admin/permission-groups";
import { AuthContext } from "./contexts";
import categories from "./admin/categories";
import videos from "./admin/videos";
import videojobs from "./admin/videojobs";
import config from "./admin/config";
import docs from "./admin/docs";

const Dashboard = () => {
  const { setAuthenticated } = React.useContext(AuthContext);
  React.useEffect(() => {
    setAuthenticated({ authenticated: true, setAuthenticated });
  }, [setAuthenticated]);

  // if (!dashboardAccess || (dashboardAccess && dashboardAccess.length === 0)) {
  //   return null;
  // }

  // if (dashboardAccess[0] === 'distributor') {
  //   return <DistributorDashboard />;
  // }

  // if (dashboardAccess[0] === 'location') {
  //   return <LocationDashboard />;
  // }

  // if (dashboardAccess[0] === 'tank') {
  //   return <TankDashboard />;
  // }

  return null;
};

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#db8746",
      main: "#db7221",
      dark: "#b34e00",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const AdminPage = () => (
  <Admin
    // dashboard={Dashboard}
    loginPage={LoginPage}
    theme={theme}
    customRoutes={customRoutes}
    layout={appLayout}
    dataProvider={dataProvider}
    history={history}
    authProvider={authProvider}
    logoutButton={LogoutButton}

  >
    {[
      <Resource
        name="users"
        options={{ label: "Users", admin: true }}
        {...users}
      />,
      <Resource
        name="devices"
        options={{ label: "Devices", admin: true }}
        {...devices}
      />,
      <Resource
        name="permission-groups"
        options={{ label: "Permission Groups", admin: true }}
        {...permissionGroups}
      />,
      <Resource
        name="categories"
        options={{ label: "Categories", admin: true }}
        {...categories}
      />,
      <Resource
        name="videos"
        options={{ label: "Videos", admin: true }}
        {...videos}
      />,
      <Resource
        name="qualities"
        options={{ label: "Qualities", admin: false }}
        {...videos}
      />,
      <Resource
        name="services"
        options={{ label: "Services", admin: false }}
        {...videos}
      />,
      <Resource
        name="videojobs"
        options={{ label: "Video Jobs", admin: true }}
        {...videojobs}
      />,
      <Resource
        name="docs"
        options={{ label: "Documents", admin: true }}
        {...docs}
      />,
      <Resource
        name="admin-users"
        options={{ label: "Admin Users", admin: true }}
        {...adminUsers}
      />,
      <Resource
        name="config"
        options={{ label: "Config", admin: true }}
        {...config}
      />,
    ]}
  </Admin>
);
export default AdminPage;
