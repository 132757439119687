import { Divider } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import React from "react";
import {
  Edit,
  SimpleForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  TextInput,
  DateTimeInput,
  BooleanInput,
  SelectArrayInput,
  PasswordInput,
  ReferenceArrayInput,
  required,
  minLength,
  email,
  number,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
} from "react-admin";
import OnlySaveToolbar from "../../components/OnlySaveToolbar";

const redirect = () => "/devices";

const validateEmail = [required(), email()];
const validateName = [required(), minLength(2)];
const validateRequired = required();
const validateNumber = number();

export default ({ permissions, ...props }) => (
  <Edit {...props}>
    <SimpleForm redirect={redirect} toolbar={<OnlySaveToolbar />}>
      <TextField source="id" label="ID" disabled />
      <TextField source="deviceId" label="MDM Device Id" disabled />
      <ReferenceField label="Device owner" source="userId" reference="users">
        <TextField source="profileName" />
      </ReferenceField>
      <TextField
        source="deviceName"
        label="Device name"
        disabled
        validate={validateName}
      />

      <ReferenceArrayField
        label="Permission Group"
        source="permissionGroupIds"
        reference="permission-groups"
        sortable={false}
        perPage={100}
        fullWidth
      >
        <SingleFieldList>
          <ChipField source="permissionGroupName"></ChipField>
        </SingleFieldList>
        {/* <SelectArrayInput optionText="permissionGroupName" /> */}
      </ReferenceArrayField>
      <br />
      <Divider fullWidth></Divider>
      <br />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);
