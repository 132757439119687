import VideosIcon from "@material-ui/icons/VideoLibrary";
import VideosList from "./VideosList";
import VideosCreate from "./VideosCreate";
import VideosEdit from "./VideosEdit";

export default {
  list: VideosList,
  edit: VideosEdit,
  create: VideosCreate,
  icon: VideosIcon,
};