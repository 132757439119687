import React from "react";
import AutocompleteVideoInput from "./AutocompleteVideoInput";
import SelectCategories from "./SelectCategories";
import { useRecordContext } from 'react-admin';

import {
  Edit,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput
} from "react-admin";

const redirect = () => "/config";

export default ({ permissions, ...props }) => {
  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput disabled source="id" label="ID" fullWidth />
        <ConfigOption />
      </SimpleForm>
    </Edit>
  )
};

const ConfigOption = (props) => {
  const record = useRecordContext();
  console.log((record && record["id"] == "homepage"));
  if (record) {
    if (record["id"] == "homepage") {
      return <div>
        <SelectCategories label="Select Category" fullWidth />
        <AutocompleteVideoInput label="Featured Videos" />
      </div>
    } else {
      return <div>
        <AutocompleteArrayInput
          source="download"
          choices={[
            { id: "GoogleDrive", name: "Google Drive" },
            { id: "Wasabi", name: "Wasabi" },
          ]}
        />
      </div>
    }
  }
  else {
    return <div />
  }

};
