import CategoriesIcon from '@material-ui/icons/Subscriptions';
import CategoriesList from "./CategoriesList";
import CategoriesEdit from "./CategoriesEdit";
import CategoriesCreate from "./CategoriesCreate";

export default {
  list: CategoriesList,
  edit: CategoriesEdit,
  create: CategoriesCreate,
  icon: CategoriesIcon,
};
