import { useMediaQuery } from "@material-ui/core";
import React from "react";
import moment from "moment-timezone";

import {
  List,
  SimpleList,
  Datagrid,
  ImageField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  FunctionField,
  ChipField,
  Filter,
  TextInput,
} from "react-admin";
import BulkActionButtons from "../components/BulkActionButtons";
import timestampFormat from "../components/DateFormat";

const ReferenceFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id"/>
  </Filter>
);

export default ({ permissions, ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={<BulkActionButtons permissions={permissions} />}
      filters={<ReferenceFilter />}
    >
      {isSmall ? (
        <SimpleList primaryText={(record) => record.id} />
      ) : (
        <Datagrid rowClick="edit" size="medium">
          <TextField source="id" label="ID" />
        </Datagrid>
      )}
    </List>
  );
};
