import UserIcon from '@material-ui/icons/Security';
import AdminUserList from './AdminUserList';
import AdminUserEdit from './AdminUserEdit';
import AdminUserCreate from './AdminUserCreate';

export default {
  list: AdminUserList,
  edit: AdminUserEdit,
  create: AdminUserCreate,
  icon: UserIcon,
};
