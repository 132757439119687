import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  minLength,
  ReferenceArrayInput,
  SelectArrayInput,
  BooleanInput,
  DateTimeInput,
  SelectInput,
  NumberInput,
  ArrayInput,
  ReferenceInput,
  AutocompleteArrayInput,
} from "react-admin";

const redirect = () => "/videos";

const validateName = [required(), minLength(2)];
const validateList = [required()];

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput disabled source="id" label="ID" fullWidth />
      <TextInput
        source="videoTitle"
        label="Title"
        fullWidth
        validate={validateName}
      />
      <TextInput source="videoSubtitle" label="Subtitle" fullWidth />
      <ReferenceArrayInput
        label="Quality"
        source="qualities"
        reference="qualities"
        perPage={10}
        fullWidth
        validate={validateList}
      >
        <SelectArrayInput optionValue="key" optionText="value" fullWidth />
      </ReferenceArrayInput>

      <ReferenceArrayInput
        label="Categories"
        source="categoryIds"
        reference="categories"
        validate={validateList}
        perPage={100}
        sort={{ field: "createdDate", order: "DESC" }}
        fullWidth
      >
        <AutocompleteArrayInput optionText="categoryName" fullWidth />
      </ReferenceArrayInput>

      <DateTimeInput
        label="Available From"
        source="availableFrom"
        defaultValue={Date.now()}
        validate={validateName}
      />
      <DateTimeInput label="Available To" source="availableTo" />

      <SelectInput
        source="sourceService"
        choices={[
          { id: "GoogleDrive", name: "Google Drive" },
          // { id: "Wasabi", name: "Wasabi" },
          { id: "Local", name: "Local file" },
        ]}
        validate={validateList}
        fullWidth
      />
      <TextInput
        source="driveURL"
        label="Resources Path"
        validate={validateName}
        fullWidth
      />
      <BooleanInput source="showWatermark" fullWidth />
      <NumberInput source="thumbnail_moment" step={1} min={1} placeholder="in seconds"/>
      {/*<TextInput source="videoLength" label="Duration" />
      <TextField source="views" label="Views" /> */}
    </SimpleForm>
  </Create>
);
