import { Avatar, useMediaQuery, withStyles } from "@material-ui/core";
import CategoriesIcon from "@material-ui/icons/Subscriptions";
import React from "react";
import moment from "moment-timezone";

import {
  List,
  SimpleList,
  Datagrid,
  ImageField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  FunctionField,
  ChipField,
  Filter,
  TextInput,
} from "react-admin";
import BulkActionButtons from "../components/BulkActionButtons";
import timestampFormat from "../components/DateFormat";

const styles = {
  image: { maxHeight: "5rem" },
};

const CategoryFilter = [
  <TextInput source="search" label="Search Category name" alwaysOn />,

  // <BooleanInput source="sort" defaultValue={false} fullWidth alwaysOn />,
];

export default withStyles(styles)(({ classes, permissions, ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List
      {...props}
      exporter={false}
      filters={CategoryFilter}
      bulkActionButtons={<BulkActionButtons permissions={permissions} />}
      sort={{ field: "createdDate", order: "DESC" }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.categoryName}
          secondaryText={(record) => record.id}
          // tertiaryText={(record) =>
          //   `${record.permissionGroupIds?.length ?? "No "} Groups assigned`
          // }
          leftIcon={(record) => {
            if (record.thumbnailURL != undefined) {
              return (
                <img
                  src={record.thumbnailURL}
                  width="60px"
                  style={{ "margin-right": "8px" }}
                />
              );
            } else {
              return <CategoriesIcon></CategoriesIcon>;
            }
          }}
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="id" label="ID" />
          <ImageField
            classes={classes}
            textAlign="center"
            source="thumbnailURL"
            label="Thumbnail"
          />
          <TextField source="categoryName" label="Name" />
          <ReferenceArrayField
            label="Groups"
            reference="permission-groups"
            source="permissionGroupIds"
            allowEmpty
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="permissionGroupName" />
            </SingleFieldList>
          </ReferenceArrayField>
          <FunctionField
            label="Created"
            source="createdDate"
            render={(record) => timestampFormat(record.createdDate)}
          />
          <FunctionField
            label="Updated"
            source="updatedDate"
            render={(record) => timestampFormat(record.updatedDate)}
          />{" "}
        </Datagrid>
      )}
    </List>
  );
});
