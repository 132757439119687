import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  minLength,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
  TextField,
} from "react-admin";

const redirect = () => "/categories";

const validateName = [required(), minLength(2)];

export default ({ permissions, ...props }) => (
  <Edit {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput disabled source="id" label="ID" />
      <TextInput
        source="categoryName"
        label="Category Name"
        validate={validateName}
        fullWidth
      />
      <TextInput
        source="categoryDescription"
        label="Category Description"
        fullWidth
      />
      <TextInput source="thumbnailURL" label="Thumbnail" fullWidth />
      <ReferenceArrayInput
        label="Permitted Groups"
        source="permittedGroups"
        reference="permission-groups"
        perPage={100}
        fullWidth
        sort={{ field: "permissionGroupName", order: "ASC" }}
      >
        <AutocompleteArrayInput optionText="permissionGroupName" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Permitted Users"
        source="permittedUsers"
        reference="users"
        sort={{ field: "profileName", order: "ASC" }}
        perPage={100}
        fullWidth
      >
        <AutocompleteArrayInput
          optionText={(record) => record.userName + " - " + record.profileName}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Permitted Devices"
        source="permittedDevices"
        reference="devices"
        sort={{ field: "deviceName", order: "ASC" }}
        perPage={100}
        fullWidth
      >
        <AutocompleteArrayInput optionText="deviceName" />
      </ReferenceArrayInput>
      
      {/* <ReferenceInput
        label="PGs"
        source="permissionGroupIds"
        reference="permission-groups"

      >
        <AutocompleteInput optionText="permissionGroupName" />
      </ReferenceInput> */}
      {/* <ReferenceArrayInput
        label="Specific Users"
        source="permissionGroupIds"
        reference="permission-groups"
        perPage={100}
        fullWidth
      >
        <SelectArrayInput optionText="permissionGroupName" />
      </ReferenceArrayInput> */}
    </SimpleForm>
  </Edit>
);
