import React from "react";
import {
  Create,
  SimpleForm,
  ReferenceArrayInput,
  TextInput,
  PasswordInput,
  BooleanInput,
  SelectArrayInput,
  required,
  minLength,
  email,
  number,
} from "react-admin";
import UserNameInput from "./UserNameInput";

const redirect = () => "/users";

const validateEmail = [required(), email()];
const validateRequired = required();
const validateName = [required(), minLength(2)];
const validatePassword = [required(), minLength(6)];
const validateNumber = number();

// const userNameValidators = [required(), ValidateUserNameUnicity];

export default (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect}>
        <TextInput source="id" label="ID" disabled fullWidth />
        {/* <TextInput source="profileName" label="Name" validate={validateName} fullWidth /> */}
        <TextInput
          source="userId"
          labelName="User Id"
          validate={validateNumber}
        />
        <TextInput
          source="deviceName"
          labelName="Devicename"
          validate={validateName}
        />
        {/* <PasswordInput source="password" label="Password" required={true} fullWidth /> */}
        <ReferenceArrayInput
          label="Permission Group"
          source="permissionGroupIds"
          reference="permission-groups"
          perPage={100}
          sort={{ field: "permissionGroupName", order: "ASC" }}
          required={true}
          fullWidth
        >
          <SelectArrayInput optionText="permissionGroupName" fullWidth />
        </ReferenceArrayInput>
        {/* <TextInput source="photoURL" label="Image" fullWidth /> */}
        <BooleanInput source="enabled" fullWidth />
      </SimpleForm>
    </Create>
  );
};

//style = {{ width: "500px" }}
