import { createSelector } from 'reselect';

/**
 * Direct selector to the test state domain
 */

const selectChangePasswordDomain = state => state.changePassword;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Test
 */

const makeSelectPassword = () => createSelector(
  selectChangePasswordDomain,
  substate => substate,
);

export default makeSelectPassword;
export { selectChangePasswordDomain };
