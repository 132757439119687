import * as React from "react";
import { useGetList, AutocompleteArrayInput } from "react-admin";
import PropTypes from "prop-types";

const AutocompleteVideoInput = (props) => {
  const { label } = props;
  var { data, ids, loading, error } = useGetList(
    "videos",
    {},
    { field: "createdDate", order: "DESC" }
  );
  if (loading) return [];
  if (error) return [];
  if (!data) return [];

  ids = ids.filter((id) => data[id].enabled);

  return (
    <AutocompleteArrayInput
      source="featured"
      label={label}
      choices={ids.map((id) => {
        return {
          id: id,
          name: `${data[id].videoTitle} - ${data[id].videoSubtitle}`,
        };
      })}
      resettable
    />
  );
};

AutocompleteVideoInput.propTypes = {
  label: PropTypes.string,
};

export default AutocompleteVideoInput;
