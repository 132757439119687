import { useMediaQuery } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";
import moment from "moment-timezone";

import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  FunctionField,
  EmailField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  TextInput,
  ImageField,
  BooleanField,
  ReferenceInput,
  SelectInput,
  Pagination,
  ReferenceField,
  AutocompleteArrayInput,
  AutocompleteInput,
} from "react-admin";
import Avatar from "@material-ui/core/Avatar";

import BulkActionButtons from "../components/BulkActionButtons";
import timestampFormat from "../components/DateFormat";
import { Person, PersonOutline } from "@material-ui/icons";

const DeviceFilter = [
  <TextInput source="search" label="Search Name" alwaysOn />,
  <ReferenceInput
    label="Permission Group"
    source="permissionGroupIds"
    reference="permission-groups"
    perPage={100}
    sort={{ field: "permissionGroupName", order: "ASC" }}
    alwaysOn
    style={{ width: "300px" }}
  >
    <AutocompleteInput optionText="permissionGroupName" />
  </ReferenceInput>,
  // <BooleanInput source="sort" defaultValue={false} fullWidth alwaysOn />,
];

const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export default ({ permissions, ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  console.log("url : ", process.env.REACT_APP_API_URL);
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      filters={DeviceFilter}
      hasCreate={false}
      pagination={<PostPagination />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.deviceName}
          // secondaryText={(record) => record.id}
          // tertiaryText={(record) => record.userId}
          leftAvatar={(record) => {
            {
              if (record.photoURL != undefined) {
                return <Avatar src={record.photoURL} />;
              } else {
                return (
                  <Avatar>
                    {record.deviceName.toUpperCase()[0]}
                    {record.deviceName.toUpperCase()[1]}
                  </Avatar>
                );
              }
            }
          }}
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="id" label="ID" />
          <TextField source="deviceId" label="MDM DeviceId" />
          <TextField source="deviceName" label="Device Name" />
          {/* <ImageField source="photoURL" label="Image" /> */}
          {/* <TextField source="profileName" label="Name" /> */}
          <ReferenceField
            label="Device owner"
            source="userId"
            reference="users"
          >
            <ChipField source="profileName" icon={<PersonIcon />} />
          </ReferenceField>
          <ReferenceArrayField
            label="Groups"
            source="permissionGroupIds"
            reference="permission-groups"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="permissionGroupName" size="small" />
            </SingleFieldList>
          </ReferenceArrayField>

          {/* <ReferenceArrayField
            label="Categories"
            reference="categories"
            source="allowedCategoryIds"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="categoryName" size="small"/>
            </SingleFieldList>
          </ReferenceArrayField>

          <ReferenceArrayField
            label="Videos"
            reference="videos"
            source="allowedVideoIds"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="videoTitle" size="small"/>
            </SingleFieldList>
          </ReferenceArrayField> */}

          <BooleanField source="enabled" label="Enabled" />
          <FunctionField
            source="createdDate"
            render={(record) => timestampFormat(record.createdDate)}
          />
          <FunctionField
            source="updatedDate"
            render={(record) => timestampFormat(record.updatedDate)}
          />
        </Datagrid>
      )}
    </List>
  );
};
