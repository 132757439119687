import PermissionGroupIcon from "@material-ui/icons/Group";
import PermissionGroupList from "./PermissionGroupList";
import PermissionGroupEdit from "./PermissionGroupEdit";
import PermissionGroupCreate from "./PermissionGroupCreate";

export default {
  list: PermissionGroupList,
  edit: PermissionGroupEdit,
  create: PermissionGroupCreate,
  icon: PermissionGroupIcon,
};
