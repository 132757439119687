import * as React from "react";
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useRecordContext, useListContext, useDataProvider, DateField, ReferenceField, EditButton } from 'react-admin';
import { Field, useFormState } from 'react-final-form';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useInput, useGetOne, useGetList, required, useRedirect } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';
import { Switch, Select } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
const crypto = require("crypto");


export function CustomHyperlinkButon(props) {
    const history = useHistory();
    const record = useRecordContext(props);
    const redirect = useRedirect();

    const path = `/docs?filter=${JSON.stringify({ parentId: record._id })}`;
    return record.type === 'pdf' ? <a>Download</a> : <a onClick={() => { history.push(path); }}>Open</a>;
}

export function CustomBackkButon() {
    const history = useHistory();
    return <Button
        variant="outlined"
        color="primary"
        onClick={() => { window.history.back(); }}
    >
        Back
    </Button >
    // return <button ><p style={{ fontWeight: '600', fontSize: '15px' }}> Back</p></button>;
}

export function CustomEditButon(props) {
    const history = useHistory();
    const record = useRecordContext(props);

    const path = `docs/${record.id}`;
    return <button onClick={() => { history.push(path); }}>Edit</button>;
}

export function DocTypeIcon(props) {
    const history = useHistory();
    const record = useRecordContext(props);

    const opacity = record.status === -1 ? 0.5 : 1;
    const pdf = <img src="https://cdn3.iconfinder.com/data/icons/dompicon-flat-file-format/256/file-pdf-format-type-512.png" style={{ opacity: `${opacity}` }} width="35px" />
    const dir = <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/Icons8_flat_folder.svg/240px-Icons8_flat_folder.svg.png" style={{ opacity: `${opacity}` }} width="40px" />

    return record.type === 'pdf' ? pdf : dir;
}

export function DocTypeIconByRecord(props) {
    const history = useHistory();
    const record = props.record;

    const opacity = record.status === -1 ? 0.5 : 1;
    const pdf = <img src="https://cdn3.iconfinder.com/data/icons/dompicon-flat-file-format/256/file-pdf-format-type-512.png" style={{ opacity: `${opacity}` }} width="35px" />
    const dir = <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/Icons8_flat_folder.svg/240px-Icons8_flat_folder.svg.png" style={{ opacity: `${opacity}` }} width="60px" />

    return record.type === 'pdf' ? pdf : dir;
}

export function DocumentName(props) {
    const history = useHistory();
    const record = useRecordContext(props);
    const pdf = <img src="https://cdn3.iconfinder.com/data/icons/dompicon-flat-file-format/256/file-pdf-format-type-512.png" width="35px" />
    const dir = <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/Icons8_flat_folder.svg/240px-Icons8_flat_folder.svg.png" width="60px" />

    return record.type === 'pdf' ? pdf : dir;
}

export function CustomFileInput(props) {
    const { source, ...rest } = props;
    const { values } = useFormState();

    return (
        values && values.type === 'pdf' && < PdfFileInput source="file" label="File" validate={required()} {...rest} />
    );
};

export function GetCurrentFolder(props) {
    const searchParams = new URLSearchParams(window.location.search);
    const filterParam = searchParams.get('filter');
    const id = filterParam ? JSON.parse(filterParam).parentId : '';
    const { data, error, loading } = useGetOne('docs', `${id}`);
    if (data) {
        return <p style={{ fontWeight: '600' }}>{data.name}</p>;
    }
    else {
        return <p style={{ fontWeight: '600' }}> Root</p>
    }
};

export function GetParentId() {
    const searchParams = new URLSearchParams(window.location.search);
    const filterParam = searchParams.get('filter');
    const id = filterParam ? JSON.parse(filterParam).parentId : 'root';
    console.log(filterParam);
    console.log(id)
    localStorage.setItem('parentId', id);
}

export const OuterDiv = (props) => {
    const record = useRecordContext(props);
    if (record && record.status === -1) {
        console.log(record.status)
        return <div className="disabled-div" >{props.children}</div>
    }
    return <div records={record}>{props.children}</div>;
}

const PdfFileInput = props => {
    const {
        input: { name, onChange, ...rest },
        meta: { touched, error },
        isRequired
    } = useInput(props);

    const onFileChange = async (file) => {
        if (!file) {
            return;
        }

        const dataUri = await fileToDataUri(file);
        const key = uuidv4().replaceAll("-", "");
        const iv = uuidv4().replaceAll("-", "").substring(16);

        const encryptedDataUri = encryptPayload(key, iv, dataUri);
        const kvi = `${key}${iv.split("").reverse().join("")}`;
        onChange({ kvi: kvi, dataUri: encryptedDataUri });

    }

    return (
        <div style={{ paddingBottom: '20px' }}>
            <label htmlFor={name}>{props.label}: </label>
            <input name={name} type="file" accept="application/pdf" onChange={async (event) => await onFileChange(event.target.files[0] || null)} />
        </div>
    );
};

export const StatusInput = props => {
    const {
        input,

        meta: { touched, error }
    } = useInput(props);

    input.checked = input.value;
    console.log(input);
    return (
       <span>
         <label>Status</label>
        <Switch
            {...input}
            
            name="status"
            checked={input.value}
        />
       </span>
    );
};




//Helper Functions
const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target.result)
    };
    reader.readAsDataURL(file);
});

function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}

function encryptPayload(key, iv, dataUri) {
    console.log(dataUri)
    const algorithm = "aes-256-cbc";
    const initVector = iv;
    const Securitykey = key;
    const decipher = crypto.createDecipheriv(algorithm, Securitykey, initVector);
    const cipher = crypto.createCipheriv(algorithm, Securitykey, initVector);
    let payload = cipher.update(String(dataUri), "utf-8", "base64");
    payload += cipher.final("base64")

    let decryptedData = decipher.update(payload, "base64", "utf-8");
    decryptedData += decipher.final();
    console.log(dataUri.substring(0, 10));
    console.log(payload.substring(0, 10));
    console.log(decryptedData.substring(0, 10));
    return payload;
}
