import React from 'react';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import TankIcon from '@material-ui/icons/Gradient';
import Check from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  button: { marginLeft: theme.spacing(1) },
  buttonXs: { marginTop: theme.spacing(1) },
  icon: { marginRight: theme.spacing(1) },
  selected: { backgroundColor: theme.palette.primary.main },
  list: { maxHeight: theme.spacing(30), overflow: 'visible' },
}));

const TankFilter = (props) => {
  const { fullScreen, onSelected } = props;

  const classes = useStyles();
  const tanks = useSelector(state => state.tankData.tanks);
  const selectedTank = useSelector(state => state.tankData.selectedTank);
  const selectedLocation = useSelector(state => state.locationData.selectedLocation);
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);

  const locationId = selectedLocation ? selectedLocation.id : '';
  const filteredTanks = tanks.filter(tank => tank.location_id === locationId);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleTankSelect = selected => () => {
    closeDialog();
    onSelected(selected);
  };

  return (
    <>
      <Button
        fullWidth={isSmall}
        variant="contained"
        color="primary"
        className={isSmall ? classes.buttonXs : classes.button}
        aria-labelledby="dialog"
        onClick={selectedTank ? openDialog : null}
      >
        <TankIcon className={classes.icon} />
        {selectedTank ? selectedTank.desc : 'Fetching tanks...'}
        <ArrowDropDown />
      </Button>
      {selectedTank ? (
        <Dialog open={open} onClose={closeDialog} maxWidth="xs" fullWidth fullScreen={fullScreen}>
          <DialogTitle id="dialog">Select a tank</DialogTitle>
          <Divider />
          <List className={classes.list}>
            {filteredTanks
            && filteredTanks.map(tank => (
              <ListItem button key={tank.id} onClick={handleTankSelect(tank)}>
                <ListItemAvatar>
                  {selectedTank.id === tank.id ? (
                    <Avatar className={classes.selected}>
                      <Check />
                    </Avatar>
                  ) : (
                    <Avatar>
                      <TankIcon />
                    </Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText primary={tank.desc} />
              </ListItem>
            ))}
          </List>
        </Dialog>
      ) : null}
    </>
  );
};

export default compose(withMobileDialog())(TankFilter);
