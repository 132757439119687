import * as React from "react";
import { useGetList, AutocompleteArrayInput } from "react-admin";

const AutocompleteVideoInput = (props) => {
  const { data, ids, loading, error } = useGetList(
    "videos",{},
    { field: "createdDate", order: "DESC" }
  );
  if (loading) return [];
  if (error) return [];
  if (!data) return [];
  return (
    <AutocompleteArrayInput
      source="videos"
      label="videos"
      resettable="true"
      choices={ids.map((id) => {
        return {
          id: id,
          name: `${data[id].videoTitle} - ${data[id].videoSubtitle}`,
        };
      })}
      fullWidth
    />
  );
};

export default AutocompleteVideoInput;
