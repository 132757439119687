import React from "react";
import AutocompleteVideoInput from "./AutocompleteVideoInput";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  minLength
} from "react-admin";

const redirect = () => "/permission-groups";
const validateName = minLength(2);

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect={redirect} >
      <TextInput disabled source="id" fullWidth />
      <TextInput source="permissionGroupName" label="Name" validate={validateName} fullWidth />
      {/* <ReferenceArrayInput
        label="Categories"
        reference="categories"
        source="categoryIds"
        perPage={100}
        fullWidth
      >
        <SelectArrayInput optionText="categoryName" fullWidth />
      </ReferenceArrayInput> */}
      <AutocompleteVideoInput />
    </SimpleForm>
  </Create>
);
