import React from 'react';
import {
  Create, SimpleForm, TextInput, required, minLength, ReferenceArrayInput, AutocompleteArrayInput,
} from 'react-admin';

const redirect = () => '/categories';

const validateName = [required(), minLength(2)];

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect={redirect}>
      {/* <TextInput source="id" label="ID" desabled fullWidth /> */}
      <TextInput
        source="categoryName"
        label="Category Name"
        validate={validateName}
        fullWidth
      />
      <TextInput
        source="categoryDescription"
        label="Category Description"
        fullWidth
      />
      <TextInput source="thumbnailURL" label="Thumbnail" fullWidth />
      <ReferenceArrayInput
        label="Permitted Groups"
        source="permittedGroups"
        reference="permission-groups"
        perPage={100}
        fullWidth
        sort={{ field: "permissionGroupName", order: "ASC" }}
      >
        <AutocompleteArrayInput optionText="permissionGroupName" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Permitted Users"
        source="permittedUsers"
        reference="users"
        sort={{ field: "profileName", order: "ASC" }}
        perPage={100}
        fullWidth
      >
        <AutocompleteArrayInput
          optionText={(record) => record.userName + " - " + record.profileName}
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Permitted Devices"
        source="permittedDevices"
        reference="devices"
        sort={{ field: "deviceName", order: "ASC" }}
        perPage={100}
        fullWidth
      >
        <AutocompleteArrayInput optionText="deviceName" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);