/*
 *
 * Change actions
 *
 */

import {
  DATA_REQUEST, DATA_SUCCESS, DATA_FAILURE, CHANGE_CREDENTIALS,
} from './constants';

export function dataRequest() {
  return {
    type: DATA_REQUEST,
  };
}
export function dataSuccess() {
  return {
    type: DATA_SUCCESS,
  };
}
export function dataFailure(error) {
  return {
    type: DATA_FAILURE,
    error,
  };
}

export function onChangeCredentials(e) {
  return {
    type: CHANGE_CREDENTIALS,
    target: e.target,
  };
}
