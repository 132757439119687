import { Avatar, useMediaQuery } from "@material-ui/core";
import React from "react";

import {
  List,
  Datagrid,
  TextField,
  SimpleList,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import BulkActionButtons from "../components/BulkActionButtons";
import timestampFormat from "../components/DateFormat";

export default ({ permissions, ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List {...props} exporter={false}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.username}
          secondaryText={(record) => record.id}
          leftAvatar={(record) => {
            return <Avatar>{record.username.toUpperCase()[0]}</Avatar>;
          }}
        />
      ) : (
        <Datagrid rowClick="edit" size="medium">
          <TextField source="id" label="ID" />
          <TextField source="username" label="User Name" />
        </Datagrid>
      )}
    </List>
  );
};
