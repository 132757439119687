import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Title, useDataProvider, useLogout } from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {
  Box,
  CircularProgress,
  FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  close: {
    padding: theme.spacing(1),
  },
  buttonBackground: {
    backgroundColor: '#F5F5F5',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  card: {
    minWidth: 275,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const REF_CATEGORY_VOLUME_UNIT = 'VOLUME_UNIT';
const REF_CATEGORY_LENGTH_UNIT = 'LENGTH_UNIT';
const REF_CATEGORY_DATE_FORMAT = 'DATE_FORMAT';
const REF_CATEGORY_TIME_FORMAT = 'TIME_FORMAT';

const json = {
  gal: 'Gallons',
  in: 'Inches',
  cm: 'Centimeters',
  ltr: 'Litres',
};

const UserSettings = (props) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [user, setUser] = React.useState();
  const [values, setValues] = React.useState({
    volume_ref_id: '',
    length_ref_id: '',
    date_ref_id: '',
    time_ref_id: '',
  });
  const [preferences, setPreferences] = React.useState();

  const { classes } = props;
  const dataProvider = useDataProvider();
  const id = localStorage.getItem('id');
  const history = useHistory();
  const logout = useLogout();

  React.useEffect(() => {
    if (id) {
      dataProvider.getOne('users', { id }).then(({ data }) => {
        setUser(data);
        setValues({
          volume_ref_id: data.volume_ref_id,
          length_ref_id: data.length_ref_id,
          date_ref_id: data.date_ref_id,
          time_ref_id: data.time_ref_id,
        });
        const _preferences = JSON.parse(localStorage.getItem('preferences'));
        setPreferences(_preferences);
      });
    } else {
      logout();
    }
  }, [id, dataProvider, logout]);

  const onChange = (name, refId, refValue, prefName) => {
    setValues(prev => ({
      ...prev,
      [name]: refId,
    }));
    setPreferences(prev => ({
      ...prev,
      [prefName]: json[refValue] || refValue,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    await dataProvider.update('users', { id, data: { ...user, ...values } });
    localStorage.setItem('preferences', JSON.stringify(preferences));
    setSubmitting(false);
    history.goBack();
  };

  if (!user) {
    return (
      <Grid container justify="center" alignItems="center" style={{ height: 'calc(100vh - 48px)' }}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <Title title="Settings" />
      <Card className={classes.card}>
        <form onSubmit={onSubmit}>
          <CardContent>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <ReferenceSelectInput
                  label="Volume Unit"
                  name="volume_ref_id"
                  prefName="volume"
                  category={REF_CATEGORY_VOLUME_UNIT}
                  defaultValue={values.volume_ref_id}
                  value={values.volume_ref_id}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ReferenceSelectInput
                  label="Length Unit"
                  name="length_ref_id"
                  prefName="length"
                  category={REF_CATEGORY_LENGTH_UNIT}
                  defaultValue={values.length_ref_id}
                  value={values.length_ref_id}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ReferenceSelectInput
                  label="Date format"
                  name="date_ref_id"
                  prefName="dateFormat"
                  category={REF_CATEGORY_DATE_FORMAT}
                  defaultValue={values.date_ref_id}
                  value={values.date_ref_id}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ReferenceSelectInput
                  label="Time format"
                  name="time_ref_id"
                  prefName="timeFormat"
                  category={REF_CATEGORY_TIME_FORMAT}
                  defaultValue={values.time_ref_id}
                  value={values.time_ref_id}
                  onChange={onChange}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classes.buttonBackground}>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                className={classes.button}
                type="submit"
                color="primary"
                disabled={submitting}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
              {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
}));

const ReferenceSelectInput = (props) => {
  const classes = useStyles();
  const [references, setReferences] = React.useState();
  const {
    label, name, prefName, defaultValue, value, onChange, category,
  } = props;
  const dataProvider = useDataProvider();

  React.useEffect(() => {
    dataProvider.getAll('references', { filter: { ref_category: category } }).then(({ data }) => {
      setReferences(data);
    });
  }, [dataProvider, category]);

  if (!references) {
    return (
      <div className={classes.textField}>
        <InputLabel>{label}</InputLabel>
        <Box pt={2}>
          <LinearProgress />
        </Box>
      </div>
    );
  }

  return (
    <FormControl className={classes.textField}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={(e) => {
          const ref = references.find(r => r.id === e.target.value);
          onChange(name, ref.id, ref.ref_value, prefName);
        }}
      >
        {references.map(e => (
          <MenuItem key={e.id} value={e.id}>
            {e.ref_value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ReferenceSelectInput.defaultProps = {
  defaultValue: undefined,
  value: undefined,
};

ReferenceSelectInput.propTypes = {
  category: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  prefName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
};

UserSettings.propTypes = {

};

export default compose(withStyles(styles))(UserSettings);
