/*
 *
 * Change Password constants
 *
 */

export const DATA_REQUEST = 'app/ChangePassword/DATA_REQUEST';
export const DATA_SUCCESS = 'app/ChangePassword/DATA_SUCCESS';
export const DATA_FAILURE = 'app/ChangePassword/DATA_FAILURE';

export const CHANGE_CREDENTIALS = 'app/ChangePassword/CHANGE_CREDENTIALS';
