import React, { useCallback } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { AuthContext, TimezoneContext } from "./contexts";
import AdminPage from "./AdminPage";
import * as actions from "./store/actions";

const localTimezone = moment.tz.guess(true);

const App = (props) => {
  const token = localStorage.getItem("token");
  console.log("token", token);
  const { setDistributorData, setLocationData, setTankData } = props;

  const [timezone, setTimezone] = React.useState(localTimezone);
  const [authenticatedData, setAuthenticatedData] = React.useState({
    authenticated: token !== null,
    setAuthenticated: (authenticated) => {
      setAuthenticatedData(authenticated);
    },
  });

  return (
    <AuthContext.Provider value={authenticatedData}>
      <TimezoneContext.Provider
        value={{
          timezone,
          setTimezone: (tz) => {
            // moment.tz.setDefault(tz);
            setTimezone(tz);
          },
        }}
      >
        <AdminPage />
      </TimezoneContext.Provider>
    </AuthContext.Provider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setDistributorData: (data) =>
    dispatch({ type: actions.SET_DISTRIBUTOR_DATA, value: data }),
  setLocationData: (data) =>
    dispatch({ type: actions.SET_LOCATION_DATA, value: data }),
  setTankData: (data) => dispatch({ type: actions.SET_TANK_DATA, value: data }),
});

const withConnect = connect(null, mapDispatchToProps);

export default withConnect(App);
