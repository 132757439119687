import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { makeStyles } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import NotificationsIcon from "@material-ui/icons/NotificationsNone";
import Check from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import { useDataProvider } from "react-admin";
import ReactTimeAgo from "react-time-ago";
import JavascriptTimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import ListItemText from "@material-ui/core/ListItemText";

JavascriptTimeAgo.locale(en);

const useStyles = makeStyles((theme) => ({
  headerMenu: {
    marginTop: theme.spacing(5),
  },
  notificationContainedShadowless: {
    boxShadow: "none",
  },
  title: {
    width: 400,
    padding: theme.spacing(2),
  },
  notificationItem: {
    width: 400,
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
  },
  ok: {
    color: "#ffffff",
    backgroundColor: green[500],
  },
  critical: {
    color: "#ffffff",
    backgroundColor: red[500],
  },
}));

const Notification = () => {
  const [notificationsMenu, setNotificationsMenu] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);

  const dataProvider = useDataProvider();

  const classes = useStyles();

  React.useEffect(() => {
    dataProvider
      .getAll("alert-notifications", { filter: "" })
      .then((response) => {
        setNotifications([]);
      });
    // eslint-disable-next-line
  }, []);

  function openNotificationsMenu(e) {
    setNotificationsMenu(e.currentTarget);
  }

  function closeNotificationsMenu() {
    setNotificationsMenu(null);
  }

  return (
    <span>
      <IconButton
        color="inherit"
        aria-haspopup="true"
        aria-controls="mail-menu"
        onClick={openNotificationsMenu}
        className={classes.headerMenuButton}
      >
        <NotificationsIcon classes={{ root: classes.headerIcon }} />
      </IconButton>

      <Menu
        id="notifications-menu"
        open={Boolean(notificationsMenu)}
        anchorEl={notificationsMenu}
        onClose={closeNotificationsMenu}
        className={classes.headerMenu}
        disableAutoFocusItem
      >
        <Typography variant="h6" className={classes.title}>
          {notifications.length === 0 && "No "}
          Recent Alerts
        </Typography>
        {notifications.map((notification) => (
          <ListItem key={notification.id} className={classes.notificationItem}>
            <ListItemAvatar>
              {notification.level === "OK" ? (
                <Avatar className={classes.ok}>
                  <Check />
                </Avatar>
              ) : (
                <Avatar className={classes.critical}>
                  <ErrorIcon />
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={notification.rule_name}
              secondary={`${notification.location_name}, ${notification.tank_id}`}
            />
            <ListItemSecondaryAction>
              <Button disabled>
                <ReactTimeAgo date={new Date(notification.timestamp)} />
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </Menu>
    </span>
  );
};

export default Notification;
