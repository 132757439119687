import React from 'react';
import {
  withStyles, Paper, Typography, useTheme,
} from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

import NoDataToShow from '../../components/NoDataToShow';
import unitMap from '../../utils/unitMap';

const SingleStat = React.memo((props) => {
  const {
    classes, stat, desc, compact, unit, rounded = true,
  } = props;

  const preferences = JSON.parse(localStorage.getItem('preferences'));

  let value = stat;

  if (unit) {
    value = stat * unitMap[preferences[unit]];
  }

  if (value) {
    value = rounded ? Math.round(value) : value.toFixed(1);
  }

  const theme = useTheme();

  if (stat === undefined) {
    return <Skeleton height={compact ? theme.spacing(16) : theme.spacing(18.5)} />;
  }

  if (!stat) {
    return (
      <Paper className={compact ? classes.rootCompact : classes.root}>
        <NoDataToShow compact />
        <br />
        <Typography color="textSecondary" align="center" variant={compact && 'caption'}>
          {desc}
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper className={compact ? classes.rootCompact : classes.root}>
      <Typography variant={compact ? 'h4' : 'h4'} color="textSecondary" align="center" className={classes.stat}>
        {value}
      </Typography>
      <br />
      <Typography color="textSecondary" align="center" variant={compact && 'caption'}>
        {desc}
      </Typography>
    </Paper>
  );
});

const styles = theme => ({
  loading: {
    height: theme.spacing(3),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3.5),
    height: theme.spacing(11.5),
  },
  stat: {
    fontWeight: 900,
  },
  rootCompact: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2.5),
    height: 91,
  },
});

export default compose(withStyles(styles, { withTheme: true }))(SingleStat);

SingleStat.defaultProps = {
  stat: undefined,
  compact: undefined,
  unit: undefined,
};

SingleStat.propTypes = {
  classes: PropTypes.object.isRequired,
  stat: PropTypes.number,
  desc: PropTypes.string.isRequired,
  compact: PropTypes.bool,
  unit: PropTypes.oneOf(['volume', 'length']),
};
