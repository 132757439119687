import { Divider } from "@material-ui/core";
import React from "react";
import {
  Edit,
  SimpleForm,
  FormTab,
  ReferenceInput,
  SelectInput,
  TextInput,
  DateTimeInput,
  BooleanInput,
  SelectArrayInput,
  PasswordInput,
  ReferenceArrayInput,
  required,
  minLength,
  email,
  number,
  Toolbar,
  SaveButton,
  TextField
} from "react-admin";
import OnlySaveToolbar from "../../components/OnlySaveToolbar";
import UserNameInput from "./UserNameInput";


const redirect = () => "/users";

const validateEmail = [required(), email()];
const validateName = [required(), minLength(2)];
const validateRequired = required();
const validateNumber = number();



export default ({ permissions, ...props }) => (
  <Edit  {...props} >
    <SimpleForm redirect={redirect} toolbar={<OnlySaveToolbar />}>
      <TextField source="id" label="ID"   />
      <TextField source="profileName" label="Name" validate={validateName}  />
      <TextField source="santId" labelName="SantId" validate={validateNumber} />
      <TextField source="userName" labelName="Username" validate={validateName} />
      {/* <PasswordField source="password" label="Password" fullWidth /> */}
      <TextField source="photoURL" label="Image"  />
      <br />
      <Divider fullWidth></Divider>
      <br />
      <BooleanInput source="enabled"  />
    </SimpleForm>
  </Edit>
);
