import DocIcon from "@material-ui/icons/Folder";
import DocsList from "./DocsList";
import DocEdit from "./DocEdit";
import DocCreate from "./DocCreate";

export default {
  list: DocsList,
  edit: DocEdit,
  create: DocCreate,
  icon: DocIcon,
};