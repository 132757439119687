/**
 *
 * Test
 *
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Title } from 'react-admin';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { onChangeCredentials, dataRequest } from './actions';
import makeSelectTest from './selectors';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  close: {
    padding: theme.spacing(1),
  },
  buttonBackground: {
    backgroundColor: '#F5F5F5',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  // container: {
  //   textAlign: 'left',
  //   marginTop: '30px',
  // },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  card: {
    minWidth: 275,
  },
});

/* eslint-disable react/prefer-stateless-function */
export class ChangePassword extends React.PureComponent {
  state = {
    error: null,
    newPassword: null,
    confirmPassword: null,
  };

  onChange = (e) => {
    const { doOnChangeCredentials } = this.props;
    if (e.target.name === 'new_password') {
      this.setState({ newPassword: e.target.value });
    }
    if (e.target.name === 'confirm_password') {
      this.setState({ confirmPassword: e.target.value });
    }
    doOnChangeCredentials(e);
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = this.state;
    const { doDataRequest } = this.props;
    if (newPassword !== confirmPassword) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
      doDataRequest();
    }
  };

  render() {
    const { classes } = this.props;
    const { error } = this.state;
    const open = true;

    return (
      <div>
        <Title title="Change Password" />
        {error ? (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={open}
            autoHideDuration={2000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Password Mismatch</span>}
          />
        ) : null}
        <Card className={classes.card}>
          <form onSubmit={this.onSubmit}>
            <CardContent>
              <TextField
                name="old_password"
                type="password"
                label="Old Password"
                onChange={this.onChange}
                className={classes.textField}
                inputProps={{
                  'aria-label': 'Description',
                }}
              />
              <br />
              <br />
              <TextField
                name="new_password"
                type="password"
                label="New Password"
                onChange={this.onChange}
                className={classes.textField}
                error={error}
                inputProps={{
                  'aria-label': 'Description',
                }}
              />
              <br />
              <br />
              <TextField
                name="confirm_password"
                type="password"
                label="Confirm Password"
                onChange={this.onChange}
                className={classes.textField}
                error={error}
                inputProps={{
                  'aria-label': 'Description',
                }}
              />
            </CardContent>
            <CardActions className={classes.buttonBackground}>
              <Button
                variant="contained"
                size="small"
                className={classes.button}
                type="submit"
                color="primary"
              >
                <SaveIcon className={(classes.leftIcon, classes.iconSmall)} />
                Save
              </Button>
            </CardActions>
          </form>
        </Card>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  doOnChangeCredentials: PropTypes.func.isRequired,
  doDataRequest: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  test: makeSelectTest(),
});

function mapDispatchToProps(dispatch) {
  return {
    doDataRequest: () => {
      dispatch(dataRequest());
    },
    doOnChangeCredentials: (e) => {
      dispatch(onChangeCredentials(e));
    },
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withStyles(styles),
  withConnect,
)(ChangePassword);
