import React from "react";
import {
  Edit,
  SimpleForm,
  minLength,
  TextField,
} from "react-admin";
import OnlySaveToolbar from "../../components/OnlySaveToolbar";


const redirect = () => "/permission-groups";
const validateName = minLength(2);

export default (props) => (
  <Edit title="Edit Manufacturer" {...props}>
    <SimpleForm redirect={redirect} toolbar={<OnlySaveToolbar />}>
      <TextField disabled source="id" fullWidth />
      <TextField
        source="permissionGroupName"
        label="Name"
        validate={validateName}
        fullWidth
      />
      <TextField source="type" label="Type" fullWidth />

      {/* <ReferenceArrayInput
        label="Categories"
        reference="categories"
        source="categoryIds"
        perPage={100}
        fullWidth
      >
        <SelectArrayInput optionText="categoryName" fullWidth />
      </ReferenceArrayInput>
      <AutocompleteVideoInput /> */}
    </SimpleForm>
  </Edit>
);
