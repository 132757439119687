import { Avatar, useMediaQuery } from "@material-ui/core";
import React from "react";
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  FunctionField,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  TextInput,
} from "react-admin";

import BulkActionButtons from "../components/BulkActionButtons";
import timestampFormat from "../components/DateFormat";

const tt = [
  <TextInput source="search" label="Search Name" alwaysOn />,
  // <ReferenceInput
  //   label="Categories"
  //   reference="categories"
  //   source="categoryIds"
  //   fullWidth
  //   alwaysOn
  // >
  //   <SelectInput optionText="categoryName" fullWidth />
  // </ReferenceInput>,
  // <BooleanInput source="sort" defaultValue={false} fullWidth alwaysOn />,
];

export default ({ permissions, ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      exporter={false}
      bulkActionButtons={false}
      filters={tt}
      hasCreate={false}
      sort={{ field: "permissionGroupName", order: "ASC" }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.permissionGroupName}
          secondaryText={(record) => record.id}
          leftAvatar={(record) => {
            var initials = record.permissionGroupName
              .toUpperCase()
              .split(" ")
              .map((word) => {
                return word[0];
              });
            var top2Initials = [];
            for (let index = 0; index < initials.length; index++) {
              if (index <= 1) {
                top2Initials[index] = initials[index];
              }
            }
            return <Avatar>{top2Initials}</Avatar>;
          }}
        />
      ) : (
        <Datagrid>
          <TextField source="id" label="ID" />
          <TextField source="permissionGroupName" label="Name" />
          {/* <ReferenceArrayField
            label="Categories"
            reference="categories"
            source="categoryIds"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="categoryName" size="small"/>
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField
            label="Videos"
            reference="videos"
            source="videos"
            sortable={false}
          >
            <SingleFieldList>
              <ChipField source="videoTitle" size="small"/>
            </SingleFieldList>
          </ReferenceArrayField> */}
          <FunctionField
            source="createdDate"
            render={(record) => timestampFormat(record.createdDate)}
          />
          <FunctionField
            source="updatedDate"
            render={(record) => timestampFormat(record.updatedDate)}
          />
        </Datagrid>
      )}
    </List>
  );
};
