import { useMediaQuery } from "@material-ui/core";
import React from "react";
import moment from "moment-timezone";

import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  FunctionField,
  EmailField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  TextInput,
  ImageField,
  BooleanField,
  ReferenceInput,
  SelectInput,
  Pagination,
  ReferenceField,
  Button,
} from "react-admin";
import Avatar from "@material-ui/core/Avatar";

import BulkActionButtons from "../components/BulkActionButtons";
import timestampFormat from "../components/DateFormat";
import { Devices } from "@material-ui/icons";

const UserFilter = [
  <TextInput source="search" label="Search Name or santId" alwaysOn />,

  // <BooleanInput source="sort" defaultValue={false} fullWidth alwaysOn />,
];

const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export default ({ permissions, ...props }) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  console.log("url : ", process.env.REACT_APP_API_URL);
  return (
    <>
      {/* <Button label="Sync Users" color="primary" /> */}
      <List
        {...props}
        bulkActionButtons={false}
        filters={UserFilter}
        exporter={false}
        hasCreate={false}
        pagination={<PostPagination />}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) => record.profileName}
            secondaryText={(record) => record.userName}
            // tertiaryText={(record) => record.id}
            leftAvatar={(record) => {
              {
                if (record.photoURL != undefined) {
                  return <Avatar src={record.photoURL} />;
                } else {
                  return (
                    <Avatar>
                      {record.userName.toUpperCase()[0]}
                      {record.userName.toUpperCase()[1]}
                    </Avatar>
                  );
                }
              }
            }}
            // tertiaryText={(record) =>
            //   timestampFormat(record.updatedDate)
            // }
          />
        ) : (
          <Datagrid rowClick="edit">
            <TextField source="id" label="ID" />
            <FunctionField
              source="photoURL"
              label="Image"
              sortable={false}
              render={(record) => (
                <Avatar src={record.photoURL} label="Image" />
              )}
            />

            <TextField source="profileName" label="Name" />
            <TextField source="santId" label="SantId" />
            <TextField source="userName" label="Username" />
            <ReferenceArrayField
              label="Devices"
              source="devices"
              reference="devices"
            >
              <SingleFieldList>
                <ChipField source="deviceName" size="small"></ChipField>
              </SingleFieldList>
            </ReferenceArrayField>

            <BooleanField source="enabled" label="Enabled" />
            <FunctionField
              source="watchLaterVideoIds"
              label="Watch Later"
              sortable={false}
              render={(record) =>
                record.watchLaterVideoIds ? record.watchLaterVideoIds.length : 0
              }
              textAlign={"center"}
            />
            <FunctionField
              source="favouriteVideoIds"
              label="Favourite"
              sortable={false}
              render={(record) =>
                record.favouriteVideoIds ? record.favouriteVideoIds.length : 0
              }
              textAlign={"center"}
            />

            <FunctionField
              source="continueWatching"
              label="Continue Watching"
              sortable={false}
              render={(record) =>
                record.continueWatching ? record.continueWatching.length : 0
              }
              textAlign={"center"}
            />

            <FunctionField
              source="createdDate"
              label="Created"
              render={(record) => timestampFormat(record.createdDate)}
            />
            <FunctionField
              source="updatedDate"
              label="Updated"
              render={(record) => timestampFormat(record.updatedDate)}
            />
          </Datagrid>
        )}
      </List>
    </>
  );
};
