import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  PasswordInput
} from "react-admin";

const redirect = () => "/admin-users";

export default (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect={redirect} fullWidth>
        <TextInput source="username" label="User Name" fullWidth />
        <PasswordInput source="password" label="Password" fullWidth />
      </SimpleForm>
    </Create>
  );
};
