import React from 'react';
import {
  Button,
  Dialog,
  Modal,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TextField,
} from '@material-ui/core';
import { withDataProvider, showNotification } from 'react-admin';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { decodeToken } from '../utils/helper';
import history from '../utils/history';

class ResetPassword extends React.Component {
  state = {
    newPassword: null,
    confirmPassword: null,
    checkModal: true,
  };

  onChange = (e) => {
    if (e.target.name === 'new_password') {
      this.setState({ newPassword: e.target.value });
    }
    if (e.target.name === 'confirm_password') {
      this.setState({ confirmPassword: e.target.value });
    }
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { newPassword, confirmPassword } = this.state;
    const { dataProvider, showNotification } = this.props;
    if (newPassword !== confirmPassword) {
      showNotification('Mismatch Password', 'error');
    } else {
      const { location } = this.props;
      const token = location.search.slice(7, location.search.length - 1);
      const decode = decodeToken(token);
      await dataProvider.update('users', {
        data: { new_password: newPassword },
        id: decode.data.id,
        type: 'reset-password',
      });
      showNotification('Password has been successfully changed', 'info', {
        autoHideDuration: 3000,
      });
      history.push('/login');
    }
  };

  render() {
    const { checkModal } = this.state;
    return (
      <div>
        <Modal open={checkModal}>
          <Dialog
            open
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">Reset Password</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <TextField
                  name="new_password"
                  type="password"
                  label="New Password"
                  onChange={this.onChange}
                  fullWidth
                  required
                  margin="normal"
                  // className={classes.textField}
                  // error={error}
                />
                <TextField
                  name="confirm_password"
                  type="password"
                  label="Confirm Password"
                  required
                  margin="normal"
                  onChange={this.onChange}
                  fullWidth
                  // className={classes.textField}
                  // error={error}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button>
                <Typography color="primary" onClick={this.onSubmit}>
                  Submit
                </Typography>
              </Button>
            </DialogActions>
          </Dialog>
        </Modal>
      </div>
    );
  }
}
ResetPassword.propTypes = {
  dataProvider: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  location: state.router.location,
});

const withConnect = connect(
  mapStateToProps,
  { showNotification },
);

export default compose(withDataProvider)(withConnect(ResetPassword));
