import moment from "moment-timezone";

const timestampFormat = (timestamp) => {
  let timeformat = "";
  timeformat = "hh:mm A";

  const xDateFormat = `DD-MM-YYYY ${timeformat}`;

  var time = new Date(timestamp);
  return moment(time).format(xDateFormat);
};

export default timestampFormat;
