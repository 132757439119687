import * as React from "react";
import { useRecordContext, useCreate, AutocompleteInput } from 'react-admin';

const RerenderVideoJob = (props) => {
    const record = useRecordContext(props);
    const videojobdata = record;
    const [create, { loading, error }] = useCreate('videojobs', videojobdata);

    if (error) return [];

    console.log(videojobdata);
    return <button disabled={loading} onClick={create}>Re Render</button>;
}
export default RerenderVideoJob;