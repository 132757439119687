/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Route } from 'react-router-dom';

import { WithPermissions } from 'react-admin';
import ChangePassword from './components/ChangePassword';
import TankDashboard from './dashboard/TankDashboard';
import DistributorDashboard from './dashboard/DistributorDashboard';
import LocationDashboard from './dashboard/LocationDashboard';
import ForgotPassword from './login/ForgotPassword';
import ResetPassword from './login/ResetPassword';
import NoAccess from './components/NoAccess';
import UserSettings from './components/UserSettings';

const LocationWithPermissions = ({ location, match }) => (
  <WithPermissions
    authParams={{ key: match.path, params: 'location-dashboard' }}
    location={location}
    render={({ permissions }) => <LocationDashboard permissions={permissions} />}
  />
);

const TankWithPermissions = ({ location, match }) => (
  <WithPermissions
    authParams={{ key: match.path, params: 'tank-dashboard' }}
    location={location}
    render={({ permissions }) => <TankDashboard permissions={permissions} />}
  />
);

const DistributorWithPermissions = ({ location, match }) => (
  <WithPermissions
    authParams={{ key: match.path, params: 'distributor-dashboard' }}
    location={location}
    render={({ permissions }) => <DistributorDashboard permissions={permissions} />}
  />
);

const ResetPasswordWithPermissions = ({ location, match }) => (
  <WithPermissions
    authParams={{ key: match.path, params: 'reset-password' }}
    location={location}
    render={({ permissions }) => <ResetPassword permissions={permissions} />}
  />
);

export default [
  // <Route exact path="/dashboard" component={Dashboard} />,
  <Route exact path="/dashboard/tank" component={TankWithPermissions} />,
  <Route exact path="/dashboard/distributor" component={DistributorWithPermissions} />,
  <Route exact path="/dashboard/location" component={LocationWithPermissions} />,
  <Route exact path="/change-password" component={ChangePassword} title="Change Password" />,
  <Route exact path="/settings" component={UserSettings} title="Settings" />,
  <Route exact path="/no-access" component={NoAccess} />,
  <Route exact path="/forgot-password" component={ForgotPassword} title="Forgot Password" />,
  <Route
    exact
    path="/reset-password"
    component={ResetPasswordWithPermissions}
    title="Reset Password"
  />,
];
