import React from 'react';
import {
  Create, SimpleForm, TextInput, required, minLength,
} from 'react-admin';

const redirect = () => '/config';

const validateName = [required(), minLength(2)];

export default props => (
  <Create title="Create Config" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="id" label="ID" fullWidth />
      <TextInput source="categoryName" label="Name" validate={validateName} fullWidth />
      <TextInput source="categoryDescription" label="Category Description" fullWidth />
      <TextInput source="thumbnailURL" label="Thumbnail" fullWidth />
    </SimpleForm>
  </Create>
);