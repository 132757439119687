import React from 'react';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { makeStyles, useTheme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { compose } from 'recompose';
import { withDataProvider } from 'react-admin';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import Skeleton from 'react-loading-skeleton';

import unitMap from '../../utils/unitMap';
import { TimezoneContext } from '../../contexts';

NoDataToDisplay(Highcharts); // initialize the module
Highcharts.setOptions({ global: { useUTC: false } });
window.moment = moment;

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  overlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'white',
    opacity: 0.7,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const TimeSeriesPanel = (props) => {
  let linesChartEl;
  const preferences = JSON.parse(localStorage.getItem('preferences'));

  const { stream, avg, days } = props;
  const classes = useStyles();
  const theme = useTheme();
  const selectedTimeRange = useSelector(state => state.timeRangeData.selectedTimeRange);
  const capacityGallons = useSelector(state => state.tankData.selectedTank
    && state.tankData.selectedTank.capacity_gallons);
  const fluidState = useSelector(state => state.tankData.selectedTank
    && state.tankData.selectedTank.fluid_state);
  const { timezone } = React.useContext(TimezoneContext);

  React.useEffect(() => {
    let chart;
    const min = selectedTimeRange.start(timezone) * 1000;
    const max = selectedTimeRange.end(timezone) * 1000;

    function drawChart() {
      let dateformat = '';
      let timeformat = '';
      switch (preferences.dateFormat) {
        case 'MM-DD-YY':
          dateformat = '%m-%d-%Y';
          break;
        case 'DD-MM-YY':
          dateformat = '%d-%m-%Y';
          break;
        default:
          dateformat = '%m-%d-%Y';
      }

      switch (preferences.timeFormat) {
        case '24 Hour':
          timeformat = '%H:%M';
          break;
        case '12 Hour':
          timeformat = '%l:%M:%p';
          break;
        default:
          timeformat = '%l:%M:%p';
      }

      const xDateFormat = `${dateformat} ${timeformat}`;

      // const dotted = [];
      // if ((stream || []).find(each => each[1] === null)) {
      //   for (let index = 1; index < stream.length - 1; index += 1) {
      //     const [prevTimestamp, prev] = stream[index - 1];
      //     const [timestamp, value] = stream[index];
      //     const [nextTimestamp, next] = stream[index + 1];
      //     if (prev !== null && value === null) {
      //       dotted.push([prevTimestamp, prev]);
      //       dotted.push([timestamp, prev]);
      //     } else if (value === null && next !== null) {
      //       dotted.push([nextTimestamp, next]);
      //       dotted.push([timestamp, next]);
      //     }
      //   }
      // }

      const options = {
        time: { timezone },
        chart: {
          animation: {
            duration: 1000,
          },
          height: 360,
          style: { fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif;' },
          zoomType: 'x',
        },
        title: {
          text: 'Tank Level',
          style: { fontSize: '1rem', fontWeight: 400, color: 'rgba(0, 0, 0, 0.54)' },
        },
        legend: { enabled: false },
        credits: { enabled: false },
        tooltip: {
          formatter() {
          // console.log(this.x);
            return `
          ${chart.time.dateFormat(xDateFormat, this.x, true)}
          <br/>
          <b>Percent:</b> ${this.y}%
          <br/>
          <b>${preferences.volume}:</b> ${(this.y * capacityGallons * unitMap[preferences.volume] / 100).toFixed(2)}
          `;
          },
        },
        plotOptions: {
          areaspline: {
            marker: {
              enabled: true,
              symbol: 'circle',
              radius: 1,
              states: { hover: { lineWidth: 2 } },
            },
            lineWidth: 2,
          },
        },
        xAxis: {
          min,
          max,
          type: 'datetime',
          dateTimeLabelFormats: { minute: '%H:%M' },
        },
        yAxis: {
          min: 0,
          title: { text: 'Percentage' },
        },
        colors: [theme.palette.primary.main],
        series: [
          {
            name: 'Tank Level',
            data: stream.filter(s => s[1] !== null),
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [
                  1,
                  Highcharts.Color(Highcharts.getOptions().colors[0])
                    .setOpacity(0)
                    .get('rgba'),
                ],
              ],
            },
            type: 'areaspline',
          },
        ],
      };

      chart = new Highcharts.Chart(linesChartEl, options);
    }

    if (stream) {
      drawChart();
      const series = chart.series[0];
      if (series) {
        series.update({ data: stream.filter(s => s[1] !== null) }, false);
        chart.xAxis[0].update({ min, max });
        chart.update({
          subtitle: {
            text: (avg !== undefined && days !== undefined)
              ? `${avg.toFixed(2)} ${preferences.volume.toLowerCase()} ${fluidState === 'New' ? 'used' : 'added'} per day`
              : '',
            style: { fontSize: '14px', color: 'rgba(0, 0, 0, 0.54)', fontWeight: 900 },
          },
        });
      }
      chart.redraw();
      chart.reflow();
    }
  }, [
    stream, selectedTimeRange, linesChartEl, theme, avg, days,
    capacityGallons, fluidState, timezone,
  ]);

  if (!stream) {
    return <Skeleton height={theme.spacing(48)} />;
  }

  return (
    <Paper className={classes.root}>
      <div
        ref={(el) => {
          linesChartEl = el;
        }}
      />
    </Paper>
  );
};

export default compose(
  withDataProvider,
)(TimeSeriesPanel);
