import { FilledInput } from "@material-ui/core";
import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  PasswordInput,
} from "react-admin";

const redirect = () => "/admin-users";

export default ({ permissions, ...props }) => (
  <Edit  {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="username" label="User Name" fullWidth />
      <PasswordInput source="password" label="Password" fullWidth />
    </SimpleForm>
  </Edit>
);
