import React from "react";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { CircularProgress, Snackbar, makeStyles } from "@material-ui/core";
import { AppBar, Button, CreateButton, RefreshButton } from "react-admin";
import MuiAlert from "@material-ui/lab/Alert";

import Logo from "../logo.png";
import Notification from "./Notification";
// import DatePicker from '../../components/DatePicker/index';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const useStyles = makeStyles((theme) => ({
  title: {
    flex: "grow",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  appBar: {
    width: "100vw",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  spacer: {
    flex: 1,
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenu: {
    marginTop: theme.spacing(5),
  },
  ModalView: {
    margin: theme.spacing(25),
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
  headerMenuButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: 28,
    color: "rgba(255, 255, 255, 0.35)",
  },
  headerIconCollapse: {
    color: "white",
  },
  profileMenu: {
    minWidth: 265,
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: "none",
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
  footerImage: {
    height: theme.spacing(6),
  },
}));

/* eslint-disable react/prefer-stateless-function */
const Appbar = (props) => {
  const notifications = "";
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openE, setOpenE] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let errorMsg = "";
  const handleClick = async () => {
    try {
      setLoading(true);
      const response_pg = await fetch(
        process.env.REACT_APP_API_URL + "/sync-permission-groups",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
          },
        }
      );
      console.log(response_pg.status);
      response_pg.json().then((data) => console.log(data));
      if (response_pg.status !== 200) {
        throw new Error("Permission Group Sync Failed");
      }
      const response_und = await fetch(
        process.env.REACT_APP_API_URL + "/sync-users-n-devices",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
          },
        }
      );
      console.log(response_und.status);
      response_und.json().then((data) => console.log(data));
      if (response_und.status !== 200) {
        throw new Error("User and Device Sync Failed");
      }
      // await delay(2000);
      // console.log("check");
      // throw new Error("Jai Maharaj!!!");
      setOpen(true);
      setLoading(false);
    } catch (error) {
      errorMsg = error.message;
      console.log(errorMsg);
      setOpenE(true);
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleCloseE = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenE(false);
  };
  return (
    <AppBar {...props} color="primary">
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Hidden only={["xs"]}>
        <div className={classes.spacer} />
        <img
          alt="logo"
          src={Logo}
          className={classes.footerImage}
          width="50"
          height="30"
        />
        <p>Akshardarshan</p>
        <div className={classes.spacer} />
        <Button
          label="Sync Users"
          color="inherit"
          onClick={handleClick}
          variant="outlined"
          startIcon={
            loading ? (
              <CircularProgress color="inherit" size={20}></CircularProgress>
            ) : null
          }
        ></Button>
        <Snackbar open={open} onClose={handleClose}>
          <MuiAlert onClose={handleClose} severity="success">
            Groups, Users and Devices Synced Successfully
          </MuiAlert>
        </Snackbar>
        <Snackbar open={openE} onClose={handleCloseE}>
          <MuiAlert onClose={handleCloseE} severity="error">
            Error occered while syncing Groups, Users and Devices : {errorMsg}
          </MuiAlert>
        </Snackbar>
      </Hidden>
    </AppBar>
  );
};

export default Appbar;
